<template>
  <BaseDialog v-model="open" @update:model-value="closeDialog()" size-class="w-full" padding-class="p-0">
    <div v-if="isInvalidAlert" class="mt-1 flex space-x-1 pl-12">
      <InformationCircleIcon class="inline h-4 w-4 cursor-pointer text-gray-700" />
      <span class="text-xs font-medium text-gray-400">
        Note: You are editing an expired alert. It will be marked active again after the update.
      </span>
    </div>
    <div v-else-if="isPausedAlert" class="mt-1 flex space-x-1 pl-12">
      <InformationCircleIcon class="inline h-4 w-4 cursor-pointer text-gray-700" />
      <span class="text-xs font-medium text-gray-400">
        Note: You are editing a paused alert. Please resume the alert after the update.
      </span>
    </div>
    <div class="h-[85vh]">
      <AlertsCustomIndex :alert="prefillAlert" />
    </div>
  </BaseDialog>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { DateTime } from 'luxon';
import AlertsCustomIndex from '@/components/alerts/custom/AlertsCustomIndex.vue';
import useHttp from '@/composeables/http';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();
const $http = useHttp();

//PREFILLING ALERTS
const prefillAlert = ref(null);
const open = ref(false);

const isInvalidAlert = computed(() => {
  return (
    prefillAlert.value?.id &&
    (prefillAlert.value.is_disabled ||
      (prefillAlert.value.expiration_date &&
        DateTime.fromISO(prefillAlert.value.expiration_date) < DateTime.now().endOf('day')))
  );
});
const isPausedAlert = computed(() => {
  return prefillAlert.value?.id && prefillAlert.value?.is_paused;
});

function fetchAlert(alertId) {
  $http.get(`/alerts/${alertId}/edit`).then(response => {
    prefillAlert.value = response.data.alert;
  });
}

onMounted(() => {
  emitter.$on('toggle:alerts-dialog', data => {
    if (data) {
      if (data.mode == 'gas') prefillAlert.value = { entity: 'Gas' };
      else if (data.alert) fetchAlert(data.alert);
    } else {
      prefillAlert.value = null;
    }
    open.value = !open.value;
  });
  emitter.$on('close:alerts-dialog', data => {
    open.value = false;
  });
  emitter.$on('open:alerts-dialog', data => {
    if (data) {
      if (data.mode == 'gas') prefillAlert.value = { entity: 'Gas' };
      else if (data.alert) fetchAlert(data.alert);
    } else {
      prefillAlert.value = null;
    }
    open.value = true;
  });
});

//CLOSE MODAL
function closeDialog() {
  emitter.$emit('clear:alert');
  open.value = false;
  prefillAlert.value = null;
}
</script>
